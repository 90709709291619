import axios1 from 'axios'
import axios from '@axios'

const axiosSource = axios1.CancelToken.source()
export default {
  namespaced: true,
  state: {
    items: [],
    running: false,
  },
  getters: {},
  mutations: {
    ITEMS(state, value) {
      state.items = value
    },
  },
  actions: {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        /*try {
          if (ctx.state.running) {
            ctx.state.running = false
            axiosSource.cancel()
          }
        } catch (e) {
        }

        ctx.state.running = true*/
        axios
          .get('/publications', { cancelToken: axiosSource.token, params: queryParams })
          .then(response => {
            ctx.state.running = false
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/item/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/publications',
          data: itemData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    actionOnItem(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: payload.path,
          data: payload.item,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/publications/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries({ state }, options) {
      axios
        .get('/countries', { params: options })
        .then(response => {
          state.pays = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchStatuses({ state }, options) {
      axios
        .get('/statuses', { params: options })
        .then(response => {
          state.statuses = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchSources({ state }, options) {
      axios
        .get('/sources', { params: options })
        .then(response => {
          state.sources = response.data.data
        })
        .catch(error => console.log(error))
    },
  },
}
