import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
    pays: [],
    sources: [],
    statuses: [],
  },
  getters: {},
  mutations: {
    USERS(state, value) {
      console.log('authors', value)
      state.items = value
    },
  },
  actions: {
    fetchAuthors(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/authors',
          params: queryParams,
          responseType: queryParams.ad && queryParams.download ? 'blob' : 'application/json', // Important for handling binary data
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/authors', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAuthor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/authors/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAuthor(ctx, authorData) {
      console.log('saving', authorData)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/authors',
          data: authorData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/pays',
          data: itemData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAvatar(ctx, imgUrl) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/authors/store_avatar',
          data: { avatar: imgUrl },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, authorData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/authors/change_password',
          data: authorData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAuthor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/authors/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAddress(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/addresses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, uri) {
      return new Promise((resolve, reject) => {
        axios
          .delete(uri)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchAuthors(ctx, options) {
      return new Promise((resolve, reject) => {
        axios
          .get('/authors', { params: options })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    searchAuthor(ctx, options) {
      return new Promise((resolve, reject) => {
        axios
          .get('/author', { params: options })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    searchPermissions(ctx, options) {
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions', { params: options })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchCountries({ state }, options) {
      axios
        .get('/countries', { params: options })
        .then(response => {
          state.pays = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchStatuses({ state }, options) {
      axios
        .get('/statuses', { params: options })
        .then(response => {
          state.statuses = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchSources({ state }, options) {
      axios
        .get('/sources', { params: options })
        .then(response => {
          state.sources = response.data.data
        })
        .catch(error => console.log(error))
    },
  },
}
