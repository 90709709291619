<template>
  <div class="d-flex align-center">
    <v-avatar
      :color="item.avatar ? '' : 'primary'"
      :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
      size="32"
    >
      <v-img
        v-if="item.avatar"
        :lazy-src="require(`@/assets/images/avatars/2.png`)"
        :src="item.avatar || require(`@/assets/images/avatars/2.png`)"
      ></v-img>
      <span
        v-else
        class="font-weight-medium"
      >{{ avatarText(item.full_name) }}</span>
    </v-avatar>

    <div class="d-flex flex-column ms-3">
      <router-link
        :to="{ name : 'author-view', params : { id : item.id } }"
        class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
      >
        {{ item.full_name }}
      </router-link>
      <div
        class="d-flex align-center"
        style="width: 100px"
      >
<!--        <v-btn
          v-if="$can('edit','Author')"
          icon
          x-small
          :to="{name:'author-info',params:{id:item.id}}"
          style="height: 14px !important; width: 14px !important;"
        >
          <v-icon

            x-small
            color="info"
          >
            {{ icons.mdiFileDocumentEditOutline }}
          </v-icon>
        </v-btn>-->
        <v-progress-linear
          :value="item.progress || 0"
          :color="getColor()"
          show-label
          rounded
          striped
        >
          <!--          <span class="bg-white white rounded px-1 py-0 body-2" style="font-size: 9px">{{ item.progress || 0 }}%</span>-->
        </v-progress-linear>
<!--        <v-btn
          icon
          x-small
          :to="{name:'author-details',params:{id:item.id}}"
          style="height: 14px !important; widt: 14px !important;"
          @click=""
        >
          <v-icon

            x-small
            color="info"
          >
            {{ icons.mdiChartBoxOutline }}
          </v-icon>
        </v-btn>-->

      </div>
    </div>
  </div>
</template>

<script>
import { mdiFileDocumentEditOutline, mdiChartBoxOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    object: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    const getColor = () => {
      if (!item.value.progress) return 'error'

      if (item.value.progress < 25) {
        return 'error'
      }
      if (item.value.progress <= 75) {
        return 'warning'
      }

      return 'success'
    }

    return {
      item,
      getColor,
      avatarText,
      icons: {
        mdiFileDocumentEditOutline,
        mdiChartBoxOutline,
      },
    }
  },
}
</script>
