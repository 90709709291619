<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Modification") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row
          v-if="isFormActive"
          class="pt-5"
        >
          <v-col
            v-if="author && author.id"
            cols="12"
          >
            <div
              v-if="author && author.id"
              class="d-flex align-center"
            >
              <v-avatar
                :color="author.avatar ? '' : 'primary'"
                :class="author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="30"
              >
                <v-img
                  v-if="author.avatar"
                  :lazy-src="require(`@/assets/images/avatars/2.png`)"
                  :src="author.avatar || require(`@/assets/images/avatars/2.png`)"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(author.full_name) }}</span>
              </v-avatar>

              <div class="d-flex flex-column ms-3">
                <router-link
                  :to="{ name : 'author-view', params : { id : author.id } }"
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ author.full_name }}
                </router-link>
                <small v-if="manuscript && manuscript.id">{{ manuscript.title }}</small>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            {{ options.info }}
          </v-col>
          <v-col
            v-for="(field,index) in options.fields || []"
            :key="field.value"
            cols="12"
          >
            <!--            <v-switch
              v-if="group"
              v-model="field.edit"
              :true-value="true"
              :false-value="false"
            ></v-switch>-->
            <v-text-field
              v-if="field.type === 'text'"
              v-model="item[field.value]"
              :label="field.label"
              outlined
              dense
              clearable
            >
            </v-text-field>
            <v-text-field
              v-else-if="field.type === 'number'"
              v-model="item[field.value]"
              :label="field.label"
              type="number"
              outlined
              dense
              clearable
            ></v-text-field>
            <v-combobox
              v-else-if="field.type === 'combo'"
              v-model="item[field.value]"
              :label="field.label"
              outlined
              dense
              multiple
              small-chips
              clearable
              clearable-chips
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-bind="attrs"
                  label
                  small
                >
                  <span class="pr-2">
                    {{ item }}
                  </span>
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                  >
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
            <v-select
              v-else-if="field.type === 'select'"
              v-model="item[field.value]"
              :label="field.label"
              :items="field.items || []"
              :item-text="field.item_text"
              :item-value="field.item_value"
              clearable
              outlined
              dense
            ></v-select>
            <date-picker
              v-else-if="field.type === 'date'"
              v-model="item[field.value]"
              :max-date="field.noLimit?null:maxDate"
              :label="field.label"
            />
            <v-autocomplete
              v-else-if="field.type === 'color'"
              v-model="item[field.value]"
              :label="field.label"
              :error-messages="errorMessages.color"
              :items="field.items"
              :item-text="field.item_text"
              :item-value="field.item_value"
              outlined
              dense
              hide-details="auto"
              clearable
            >
              <template #item="{ item }">
                <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item[field.item_text] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-item>
              </template>
              <template #selection="{ item }">
                <v-item
                  :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                  style="padding: 0px 4px;"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.color_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-item>
              </template>
            </v-autocomplete>
            <v-textarea
              v-else-if="field.type === 'textarea'"
              v-model="item[field.value]"
              outlined
              rows="2"
              auto-grow
              clearable
              dense
              :label="field.label"
              :placeholder="field.label"
              hide-details="auto"
            ></v-textarea>
            <template v-else-if="field.type === 'checkbox'">
              <v-switch
                v-model="item[field.value]"
                :label="field.label"
                :true-value="options.trueValue"
                :false-value="options.falseValue"
              ></v-switch>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Confirmer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="successful?$vuetify.theme.currentTheme.success:$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker, AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
    isFormActive: {
      type: Boolean,
    },
    group: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const form = ref()
    const errorMessages = ref({ })
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const successful = ref(false)
    const valid = ref(false)
    const modalDate = ref(false)
    const dialogDate = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const blocked = ref([
      { id: 'DD', label: 'Dossier décalé' },
      { id: 'Pmt', label: 'Paiement' },
      { id: 'NS', label: 'Non signé' },
      { id: 'FW', label: 'Fichier Word' },

      /* { id: "vide", label: 'Ouvert' }, */
    ])
    const maxDate = ref((new Date(Date.now())).toISOString().substr(0, 10))

    const getItemValue = (model, str, d = null) => {
      if (model == null || !str) {
        return d
      }
      try {
        if (str.indexOf('.') < 0) {
          return model[str] || d
        }
        const current = str.slice(0, (str.indexOf('.')))
        const next = str.slice((str.indexOf('.')) + 1)
        model = model[current]

        return (model) ? this.getItemValue(model, next, d) : ''
      } catch (e) {
        return d
      }
    }
    const getItem = (items, field, value) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i][field] == value) {
          return items[i]
        }
      }

      return false
    }

    const validate = () => {
      form.value.validate()
    }
    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })

    const author = computed({
      get: () => getItemValue(item.value, props.config.author),
    })
    const manuscript = computed({
      get: () => getItemValue(item.value, props.config.manuscript, item.value),
    })

    if (props.group) {
      props.options.fields.map(ele =>

      // ele.edit = false

        ele)
      console.log(props.options.fields)
    }

    const onSubmit = () => {
      if (valid.value) {
        const payload = {
          id: item.value.id,
          column: props.options.column_name || props.options.value,
          label: props.options.text,
          group: props.group,
        }
        if (props.selected && props.selected.length > 0) {
          payload.ids = props.selected.map(ele => ele.id)
        } else {
          payload.ids = [item.value.id]
        }
        if (props.options.fields && props.options.fields.length > 0) {
          for (let i = 0; i < props.options.fields.length; i++) {
            if (props.options.fields[i].required && !item.value[props.options.fields[i].value]) {
              isSnackbarBottomVisible.value = true
              successful.value = false
              snackBarMessage.value = `Le champ "${props.options.fields[i].label}" est requis`

              return false
            }
            if (props.options.fields[i].value === props.options.value) {
              payload.with_value = true
            }
            payload[`${props.options.fields[i].value}_type`] = props.options.fields[i].type
            payload[props.options.fields[i].value] = item.value[props.options.fields[i].value]
          }
        }

        loading.value = true
        axios.post(`${props.config.url}${props.options.url || props.options.value}/${payload.ids[0]}`,
          payload)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                successful.value = false
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            if (response.data.message) {
              item.value = response.data
              isSnackbarBottomVisible.value = true
              successful.value = response.data.message || true
              snackBarMessage.value = response.data.message || props.options.success_message || `${props.options.text} enregistré avec succès`
              setTimeout(() => {
                // emit('update:is-form-active', false)
                emit('saved', response.data)
              }, 4000)
            } else {
              // emit('update:is-form-active', false)
              emit('saved', response.data)
            }

            // item.value.comment = null
          }).catch(error => {
            isSnackbarBottomVisible.value = true
            successful.value = false
            snackBarMessage.value = 'Erreur lors de l\'enregistrement des données'
            loading.value = false
            console.log('error', error)
            if (error.data && error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    const setColor = () => {
      if (item.value[props.options.colors] && item.value[props.options.colors].length > 0) {
        const records = item.value[props.options.colors].filter(ele => ele[props.options.color_reference] === (props.options.color_column || props.options.value))

        if (records && records.length) {
          item.value.color_id = records[0].color_id || null
          item.value.fore_color_id = records[0].fore_color_id || null
        }
      } else {
        item.value.color_id = null
      }
    }
    setColor()
    watch(item, () => {
      // setColor()

    }, {
      deep: true,
    })

    return {
      form,
      maxDate,
      blocked,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      successful,
      search,
      authors,
      author,
      manuscript,
      isLoadingAuthors,
      onSubmit,
      modalDate,
      dialogDate,
      item,
      valid,
      dialog,
      loading,
      validate,
      avatarText,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
